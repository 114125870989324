<script setup lang="ts">
import { useOrder } from 'store/order';
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { scrollToElement } from 'utils/scroll-to-element';
import * as communesApi from 'api/communes';
import * as communeCompanyItemsApi from 'api/commune-company-items';
import type { Distributor } from 'types/distributor';
import find from 'lodash/find';
import TheHeader from './the-header.vue';
import GasTable from './gas-table.vue';
import ReferForm from './refer-form.vue';
import SubscriptionForm from './subscription-form.vue';
import OrderTrackingHome from './order-tracking-home.vue';
import BaseCallout from './base-callout.vue';
import FrequentQuestions from './frequent-questions.vue';
import UsersTestimonies from './users-testimonies.vue';

interface Props {
  regions: Region[]
  gasCompanies: GasCompany[]
  distributors: Distributor[]
  initialRegion?: Region
  initialCommune?: Commune
  initialCommuneCompanyItems?: CommuneCompanyItem[]
  frequentQuestions: FrequentQuestion[]
  usersTestimonies: UserTestimony[]
  slowDeliveryMode: boolean
}

const props = withDefaults(defineProps<Props>(), {
  initialRegion: undefined,
  initialCommune: undefined,
  initialCommuneCompanyItems: undefined,
});

const orderStore = useOrder();
orderStore.resetOrder();
orderStore.resetPrefilled();

const selectorsDiv = ref<HTMLElement>();
const refferDiv = ref<HTMLElement>();
const subscriptionDiv = ref<HTMLElement>();
const missionDiv = ref<HTMLElement>();
const tipsDiv = ref<HTMLElement>();
const frequentQuestionsDiv = ref<HTMLElement>();
const distributorDiv = ref<HTMLElement>();

const menuItems = computed<MenuItem[]>(() => [
  { label: 'Cotiza y pide', element: selectorsDiv.value, scrollTo: 'start' },
  { label: 'Refiere y gana', element: refferDiv.value, scrollTo: 'center' },
  { label: 'Qué es HAY Gas', element: missionDiv.value, scrollTo: 'center' },
  { label: 'Seguir pedido' },
  { label: 'Preguntas frecuentes', element: frequentQuestionsDiv.value, scrollTo: 'center' },
  { label: 'Ahorra siempre', element: subscriptionDiv.value, scrollTo: 'center' },
  { label: 'HAY Dato', element: tipsDiv.value, scrollTo: 'start' },
  { label: '¿Eres distribuidor?', element: distributorDiv.value, scrollTo: 'center' },
],
);

const communes = ref<Commune[]>([]);
const selectedRegion = ref(props.initialRegion ? props.initialRegion.id : 0);
const selectedCommuneId = ref(props.initialCommune ? props.initialCommune.id : 0);
const error = ref(false);
const communeCompanyItems = ref(props.initialCommuneCompanyItems ? props.initialCommuneCompanyItems : []);

const currentCommune = ref<Commune | undefined>(props.initialCommune);
const currentRegion = ref<Region | undefined>(props.initialRegion);
const gasTableDiv = ref<HTMLElement>();

const isCommuneCompanyItemsEmpty = computed(() => (communeCompanyItems.value.length === 0));
const showTable = ref(!isCommuneCompanyItemsEmpty.value);
function sortCommunes(notSortedCommunes:Commune[]) {
  return notSortedCommunes.sort((prev:Commune, curr:Commune) => {
    const collator = new Intl.Collator('es');

    return collator.compare(prev.name, curr.name);
  });
}

async function getCommunes(regionId:number) {
  try {
    const response = await communesApi.index(regionId);
    communes.value = sortCommunes(response);
  } catch (e) {
    error.value = true;
  }
}

function selectRegion(regionId:number) {
  selectedCommuneId.value = 0;
  getCommunes(regionId);
}

function updateUrl() {
  const url = new URL(window.location.href);
  url.pathname = `/precios/${currentRegion.value?.slug}/${currentCommune.value?.slug}`;
  window.history.replaceState({}, '', url);
}

function setCurrentCommuneAndRegion() {
  currentCommune.value = find(communes.value, { id: selectedCommuneId.value });
  currentRegion.value = find(props.regions, { id: selectedRegion.value });
  orderStore.resetOrder();
  orderStore.setPrefillCommuneId(selectedCommuneId.value);
  orderStore.setPrefillRegionId(selectedRegion.value);
}

async function getCommuneCompanyItems(communeId:number) {
  try {
    const response = await communeCompanyItemsApi.index(communeId);
    communeCompanyItems.value = response;
    showTable.value = !isCommuneCompanyItemsEmpty.value;
  } catch (e) {
    error.value = true;
  }
}

watch(communeCompanyItems, (newCommuneCompanyItems) => {
  orderStore.setCommuneWithHayGasPrice(newCommuneCompanyItems.some((item) => item.hayGas));
});

async function getGasItems(communeId:number) {
  try {
    getCommuneCompanyItems(communeId);
    setCurrentCommuneAndRegion();
    updateUrl();
    await nextTick();
    scrollToElement(gasTableDiv.value, 'center');
  } catch (e) {
    error.value = true;
  }
}

function handleSubcriptionCreated(subscription: SubscriptionFormItem) {
  selectedCommuneId.value = Number(subscription.communeId);
  selectedRegion.value = Number(subscription.regionId);
  getCommunes(selectedRegion.value);
  getGasItems(selectedCommuneId.value);
}

async function resetGasTable() {
  try {
    currentCommune.value = undefined;
    currentRegion.value = undefined;
    selectedRegion.value = 0;
    selectedCommuneId.value = 0;
    showTable.value = false;
    window.history.replaceState({}, '', '/');
    await nextTick();
    scrollToElement(selectorsDiv.value, 'center');
  } catch (e) {
    error.value = true;
  }
}

const variantSearchButton = computed(() =>
  (!selectedRegion.value || !selectedCommuneId.value ? 'disabled' : 'primary'),
);

onMounted(() => {
  if (props.initialRegion !== undefined && props.initialCommune !== undefined) {
    getCommunes(selectedRegion.value);
    scrollToElement(gasTableDiv.value, 'center');
    setCurrentCommuneAndRegion();
  }
  orderStore.setCommuneWithHayGasPrice(communeCompanyItems.value.some((item) => item.hayGas));
});

const FEEDBACK_URL = process.env.FEEDBACK_URL;
const DISTRIBUTOR_FORM_URL = process.env.DISTRIBUTOR_FORM_URL;
const showSlowDeliveryModal = ref(props.slowDeliveryMode);

function closeSlowDeliveryModal() {
  showSlowDeliveryModal.value = false;
}

const gasCompaniesNames = [
  'abastible',
  'gasco',
  'lipigas',
];

</script>
<template>
  <the-header
    :menu-items="menuItems"
  />
  <div class="relative mx-auto flex max-w-md flex-col items-center gap-10 text-white sm:max-w-screen-sm md:mt-20 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
    <img
      class="absolute -right-14 -top-3 -z-10 h-64 w-64 self-start md:-top-28 md:h-auto md:w-auto xl:-right-52"
      :src="require('assets/images/ray.png')"
    >
    <div class="mt-4 flex flex-col items-center gap-6 md:gap-4">
      <p class="text-center font-display text-4xl uppercase leading-normal md:text-5xl">
        PIDE tu GAS con entrega inmediata<br>
        ¡más barato!
      </p>
      <p class="text-center text-2xl leading-normal">
        Recibimos cilindros de todos los colores.
      </p>
    </div>
    <div class="flex w-full flex-wrap justify-center gap-6 md:gap-12">
      <div
        v-for="gasCompanyName in gasCompaniesNames"
        :key="gasCompanyName"
      >
        <inline-svg
          :src="require(`assets/images/${gasCompanyName}.svg`)"
        />
      </div>
    </div>
    <section
      ref="selectorsDiv"
      class="flex w-full flex-col gap-6 rounded-3xl bg-hg-pink/10 p-4 md:gap-8 md:p-8"
    >
      <p class="text-center font-display text-3xl uppercase md:text-5xl">
        Cotiza y pide aquí:
      </p>
      <div class="flex flex-col md:flex-row md:gap-8">
        <base-select
          v-model.number="selectedRegion"
          :options="regions"
          label="Región"
          name="region"
          empty-label="Ej.: de los Ríos"
          allow-empty
          class="w-full"
          @update:model-value="selectRegion(selectedRegion)"
        />
        <base-select
          v-model.number="selectedCommuneId"
          :options="communes"
          label="Comuna"
          name="commune"
          empty-label="Ej.: Valdivia"
          allow-empty
          class="w-full"
          @update:model-value="getGasItems(selectedCommuneId)"
        />
      </div>
      <div class="flex flex-col justify-center gap-4 md:flex-row md:space-y-0 md:space-x-16">
        <base-button
          v-if="showTable"
          id="reset-search-button"
          class="self-center"
          variant="secondary"
          size="md"
          @click="resetGasTable()"
        >
          Limpiar búsqueda
        </base-button>
        <base-button
          id="search-prices-button"
          class="self-center"
          :variant="variantSearchButton"
          size="md"
          @click="getGasItems(selectedCommuneId)"
        >
          {{ showTable ? 'Nueva búsqueda' : 'Busca y pide' }}
        </base-button>
        <base-button
          id="repeat-order-button"
          class="self-center"
          gtm-id="repeat-order-home-button"
          :variant="'primaryOutline'"
          :href="'/orders/repeat'"
          size="md"
        >
          Repetir mi último pedido
        </base-button>
      </div>
    </section>
    <section
      class="w-full"
    >
      <base-alert
        v-if="isCommuneCompanyItemsEmpty && currentCommune"
      >
        Por el momento no tenemos distribuidores asociados en esta comuna. ¡Trabajaremos para ello!
      </base-alert>
      <div
        v-if="showTable"
        ref="gasTableDiv"
        gtm-id="price-table"
      >
        <base-modal
          :open="showSlowDeliveryModal"
          @close="closeSlowDeliveryModal"
          @first-button-click="closeSlowDeliveryModal"
        >
          <template #title>
            ¡Fiestas Patrias!
          </template>
          <template #description>
            Algunos de nuestros distribuidores cierran más temprano los días 18 y 19,
            por lo que pueden haber menos servicios disponibles
          </template>
          <template #firstButtonTitle>
            Entendido
          </template>
        </base-modal>
        <gas-table
          :commune-company-items="communeCompanyItems"
          :distributors="distributors"
        />
      </div>
    </section>
    <base-callout>
      <p class="p-3 text-left md:p-6 md:text-center md:text-2xl">
        Pide aquí y paga al momento de la entrega.
      </p>
    </base-callout>
    <section ref="refferDiv">
      <refer-form />
    </section>
    <section>
      <users-testimonies
        v-if="usersTestimonies && usersTestimonies.length > 0"
        :users-testimonies="usersTestimonies"
      />
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section ref="missionDiv">
      <base-callout class="rounded-2xl">
        <div class="space-y-6 p-6 md:py-10 md:px-11">
          <h2 class="font-display text-3xl md:text-[40px]">
            ¿QUÉ ES HAY GAS?
          </h2>
          <p class="md:text-2xl">
            HAY Gas es una plataforma que busca conectarte con los mejores distribuidores de gas de cada comuna.
            Seleccionamos distribuidores baratos y confiables para que ahorres dinero y tiempo en cada pedido de gas.
            Para que el gas deje de ser un problema...<br>
            <strong>¡Pídelo por HAY Gas!</strong>
          </p>
        </div>
      </base-callout>
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section
      ref="trackingDiv"
      class="w-full"
    >
      <order-tracking-home
        :regions="regions"
      />
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section
      v-if="frequentQuestions.length > 0"
      ref="frequentQuestionsDiv"
      class="w-full"
    >
      <frequent-questions
        :frequent-questions="frequentQuestions"
      />
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section
      ref="subscriptionDiv"
      class="w-full"
    >
      <subscription-form
        :regions="regions"
        @subscription-created="(subscription) => handleSubcriptionCreated(subscription)"
      />
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section
      class="flex w-full overflow-hidden rounded-2xl bg-hg-pink/20 p-4 md:p-7"
    >
      <div class="relative w-1/4 md:w-[12.5%]">
        <img
          :src="require('assets/images/reminder-2.png')"
          class="absolute -right-1 top-2 w-40 max-w-none md:right-0 md:-top-4"
        >
      </div>
      <div class="flex w-3/4 flex-col gap-2 md:w-[87.5%]">
        <p class="text-xlmd:text-[45px] md:leading-10">
          Queremos seguir mejorando y nos interesa tu opinión. ¿Tienes 2 minutos?
        </p>
        <base-button
          id="feedback-form"
          variant="primaryOutline"
          size="md"
          type="button"
          :href="FEEDBACK_URL"
        >
          ¡Vamos!
        </base-button>
      </div>
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section class="flex w-full flex-col gap-14 md:gap-14">
      <div
        ref="tipsDiv"
        class="flex scroll-mt-24 flex-col gap-5 md:scroll-mt-48"
      >
        <p class="font-display text-3xl md:text-[40px]">
          ¡HAY DATO!
        </p>
        <p>
          Da lo mismo la marca de tu cilindro vacío, ¡siempre puedes pedir gas a la compañía más barata!
          Ellos están obligados a llevarse tu cilindro vacío, aunque sea de otra marca.
        </p>
      </div>
      <div class="flex flex-col items-center gap-12 text-xl md:flex-row md:items-baseline md:justify-between md:space-y-0 md:text-2xl">
        <div class="flex w-72 flex-col items-center justify-between px-10 text-center">
          <img :src="require('assets/images/reminder-1.png')">
          <p class="mt-3 md:mt-9">
            Puedes intercambiar de proveedor
          </p>
        </div>
        <div class="flex w-72 flex-col items-center justify-between px-10 text-center">
          <img :src="require('assets/images/reminder-3.png')">
          <p class="mt-3 md:mt-9">
            No hay costos asociado al cambio
          </p>
        </div>
        <div class="flex w-72 flex-col items-center justify-between px-10 text-center">
          <img :src="require('assets/images/reminder-2.png')">
          <p class="mt-3 md:mt-9">
            Recibimos todo tipo de cilindros
          </p>
        </div>
      </div>
    </section>
    <div class="inline-flex h-px w-full bg-pink-500" />
    <section class="flex w-full flex-col gap-4 overflow-hidden rounded-2xl bg-hg-pink/20 p-4 md:p-7">
      <div class="flex w-full flex-col gap-4">
        <h1
          ref="distributorDiv"
          class="font-display text-4xl uppercase md:leading-10"
        >
          ¿Eres distribuidor?
        </h1>
        <p class="md:text-xl md:leading-7">
          En HAY Gas sabemos que no puede faltar el gas y trabajamos para que nuestros pedidos
          se entreguen lo más rápido posible.
          Si eres distribuidor y te interesa sumarte a HAY Gas, completa tu registro aquí y te contactaremos.
        </p>
      </div>
      <div class="flex w-full justify-center">
        <a
          id="feedback-form"
          :href="DISTRIBUTOR_FORM_URL"
          target="_blank"
          rel="noopener"
          class="flex w-fit items-center justify-center gap-2 rounded-lg bg-hg-pink px-6 py-1 font-bold text-zinc-100 md:py-2 md:px-10"
        >
          Llenar el formulario
        </a>
      </div>
    </section>
    <base-button
      id="go-to-table-button"
      variant="primary"
      size="xl"
      type="button"
      @click="scrollToElement(selectorsDiv, 'center')"
    >
      ¡Hacer pedido a domicilio!
    </base-button>
  </div>
</template>
<style scoped>
.bg-purple-15 {
  background-color: rgba(254, 252, 232, 0.15);
}
</style>
