@font-face {
  font-family: 'Palmer Lake Print';
  src: url('./palmer-lake-print/PalmerLakePrint-Regular.eot');
  src: local('Palmer Lake Print'), local('PalmerLakePrint-Regular'),
    url('./palmer-lake-print/PalmerLakePrint-Regular.eot?#iefix') format('embedded-opentype'),
    url('./palmer-lake-print/PalmerLakePrint-Regular.woff2') format('woff2'),
    url('./palmer-lake-print/PalmerLakePrint-Regular.woff') format('woff'),
    url('./palmer-lake-print/PalmerLakePrint-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
