@font-face {
  font-family: 'Don José';
  src: url('./don-jose/DonJose-Black.eot');
  src: local('Don José'), local('DonJose-Black'),
    url('./don-jose/DonJose-Black.eot?#iefix') format('embedded-opentype'),
    url('./don-jose/DonJose-Black.woff2') format('woff2'),
    url('./don-jose/DonJose-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
